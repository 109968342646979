<template>
  <div>

    <div class='row'>
      <div class='col-lg-4 col-md-12'>
        <div class='card card-top card-top-primary'>
          <div class='card-header'>
            <div class='d-flex justify-content-between'>
              <vb-headers-card-header :data="{ title: 'Genel Bildirimler Gönderilsin Mi?' }" />
              <a-switch v-model:checked.sync='isBroadcastPushNotificationsEnabled' />
            </div>
          </div>
          <div class='card-body'>
            Haftalık yayın fikstürü ve canlı yayın başlıyor bildirimleri gönderilsin mi?
          </div>
        </div>
      </div>
      <div class='col-lg-4 col-md-12'>
        <div class='card card-top card-top-primary'>
          <div class='card-header'>
            <div class='d-flex justify-content-between'>
              <vb-headers-card-header :data="{ title: 'Kişisel Bildirimler Gönderilsin Mi?' }" />
              <a-switch v-model:checked.sync='isSingleUserPushNotificationsEnabled' />
            </div>
          </div>
          <div class='card-body'>
            Rozet kazandınız gibi bildirimler gönderilsin mi?
          </div>
        </div>
      </div>
      <div class='col-lg-4 col-md-12'>
        <div class='card card-top card-top-primary'>
          <div class='card-header'>
            <div class='d-flex justify-content-between'>
              <vb-headers-card-header :data="{ title: 'Premium Olmayan Kullanıcılar Kaç Günde Bir Ücretsiz Canlı Yayına Girebilir?' }" />
              <a-badge :count='daysBetweenNonPremiumUsersAttendingLiveStreamsFromServer' :number-style="{ backgroundColor: '#52c41a' }" />
            </div>
          </div>
          <div class='card-body'>
            <div class='d-flex align-items-center'>
              <span class='mr-4'>Gün</span>
              <a-input v-model:value='daysBetweenNonPremiumUsersAttendingLiveStreams' class='col-6' placeholder='Version' />
            </div>
            <div class='justify-content-center'>
              <a-button v-if='showDaysBetweenNonPremiumUsersAttendingLiveStreamsSaveButton' @click='changeDaysBetweenNonPremiumUsersAttendingLiveStreams'
                        class='btn btn-success mt-3'>Güncelle
              </a-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class='row'>
      <div class='col-lg-4 col-md-12'>
        <div class='card card-top card-top-primary'>
          <div class='card-header'>
            <div class='d-flex justify-content-between'>
              <vb-headers-card-header :data="{ title: 'Sistem Bakım Modu' }" />
              <a-switch v-model:checked.sync='isInMaintenance' />
            </div>
          </div>
          <div class='card-body'>
            Sistemi bakım moduna almak için sistem bakım modunu sağ üst köşeden açabilirsiniz.
          </div>
        </div>
      </div>
      <div class='col-lg-4 col-md-12'>
        <div class='card card-top card-top-primary'>
          <div class='card-header'>
            <div class='d-flex justify-content-between'>
              <vb-headers-card-header :data="{ title: 'Minimum IOS Version' }" />
              <a-badge :count='minimumIosVersionFromServer' :number-style="{ backgroundColor: '#52c41a' }" />
            </div>
          </div>
          <div class='card-body'>
            <div class='d-flex align-items-center'>
              <span class='mr-4'>Version</span>
              <a-input v-model:value='minimumIosVersion' class='col-6' placeholder='Version' />
              <a-badge
                class='ml-2'
                v-if='showMinIosVersionSaveButton && !isMinIosVersionCorrectForSemver'
                count="Sürüm Semver'e Uygun Değil"
                :number-style="{ color: '#FFF', backgroundColor: '#a0061d' }"
              />
            </div>
            <div class='justify-content-center'>
              <a-button v-if='showMinIosVersionSaveButton && isMinIosVersionCorrectForSemver' @click='changeMinIosVersion' class='btn btn-success mt-3'>
                Güncelle
              </a-button>
            </div>
          </div>
        </div>
      </div>
      <div class='col-lg-4 col-md-12'>
        <div class='card card-top card-top-primary'>
          <div class='card-header'>
            <div class='d-flex justify-content-between'>
              <vb-headers-card-header :data="{ title: 'Minimum Android Version' }" />
              <a-badge :count='minimumAndroidVersionFromServer' :number-style="{ backgroundColor: '#52c41a' }" />
            </div>
          </div>
          <div class='card-body'>
            <div class='d-flex align-items-center'>
              <span class='mr-4'>Version</span>
              <a-input v-model:value='minimumAndroidVersion' class='col-6' placeholder='Version' />
              <a-badge
                class='ml-2'
                v-if='showMinAndroidVersionSaveButton && !isMinAndroidVersionCorrectForSemver'
                count="Sürüm Semver'e Uygun Değil"
                :number-style="{ color: '#FFF', backgroundColor: '#a0061d' }"
              />
            </div>
            <div class='justify-content-center'>
              <a-button v-if='showMinAndroidVersionSaveButton && isMinAndroidVersionCorrectForSemver' @click='changeMinAndroidVersion'
                        class='btn btn-success mt-3'>Güncelle
              </a-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class='row'>
      <div class='col-lg-4 col-md-12'></div>
      <div class='col-lg-4 col-md-12'>
        <div class='card card-top card-top-primary'>
          <div class='card-header'>
            <div class='d-flex justify-content-between'>
              <vb-headers-card-header :data="{ title: 'Mevcut IOS Version' }" />
              <a-badge :count='currentIosVersionFromServer' :number-style="{ backgroundColor: '#52c41a' }" />
            </div>
          </div>
          <div class='card-body'>
            <div class='d-flex align-items-center'>
              <span class='mr-4'>Version</span>
              <a-input v-model:value='currentIosVersion' class='col-6' placeholder='Version' />
              <a-badge
                class='ml-2'
                v-if='showCurrentIosVersionSaveButton && !isCurrentIosVersionCorrectForSemver'
                count="Sürüm Semver'e Uygun Değil"
                :number-style="{ color: '#FFF', backgroundColor: '#a0061d' }"
              />
            </div>
            <div class='justify-content-center'>
              <a-button class='btn btn-success mt-3' @click='changeCurrentIosVersion'
                        v-if='showCurrentIosVersionSaveButton && isCurrentIosVersionCorrectForSemver'>Güncelle
              </a-button>
            </div>
          </div>
        </div>
      </div>
      <div class='col-lg-4 col-md-12'>
        <div class='card card-top card-top-primary'>
          <div class='card-header'>
            <div class='d-flex justify-content-between'>
              <vb-headers-card-header :data="{ title: 'Mevcut Android Version' }" />
              <a-badge :count='currentAndroidVersionFromServer' :number-style="{ backgroundColor: '#52c41a' }" />
            </div>
          </div>
          <div class='card-body'>
            <div class='d-flex align-items-center'>
              <span class='mr-4'>Version</span>
              <a-input v-model:value='currentAndroidVersion' class='col-6' placeholder='Version' />
              <a-badge
                class='ml-2'
                v-if='showCurrentAndroidVersionSaveButton && !isCurrentAndroidVersionCorrectForSemver'
                count="Sürüm Semver'e Uygun Değil"
                :number-style="{ color: '#FFF', backgroundColor: '#a0061d' }"
              />
            </div>
            <div class='justify-content-center'>
              <a-button v-if='showCurrentAndroidVersionSaveButton && isCurrentAndroidVersionCorrectForSemver' @click='changeCurrentAndroidVersion'
                        class='btn btn-success mt-3'>Güncelle
              </a-button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { appInfoAdministration } from '../../services/api-service'
import { notification } from 'ant-design-vue'
import * as semver from 'semver'

export default {
  name: 'VbAyarlar',
  data() {
    return {
      isRollback: false,
      isInMaintenance: false,
      minimumIosVersion: '1.0.0',
      minimumIosVersionFromServer: '1.0.0',
      currentIosVersion: '1.0.0',
      currentIosVersionFromServer: '1.0.0',
      minimumAndroidVersion: '1.0.0',
      minimumAndroidVersionFromServer: '1.0.0',
      currentAndroidVersion: '1.0.0',
      currentAndroidVersionFromServer: '1.0.0',
      isBroadcastPushNotificationsEnabled: false,
      isBroadcastPushNotificationsEnabledRollback: false,
      isSingleUserPushNotificationsEnabled: false,
      isSingleUserPushNotificationsEnabledRollback: false,
      daysBetweenNonPremiumUsersAttendingLiveStreams: 7,
      daysBetweenNonPremiumUsersAttendingLiveStreamsFromServer: 7,
    }
  },
  computed: {
    showMinAndroidVersionSaveButton() {
      return this.minimumAndroidVersion !== this.minimumAndroidVersionFromServer
    },
    showMinIosVersionSaveButton() {
      return this.minimumIosVersion !== this.minimumIosVersionFromServer
    },
    showCurrentIosVersionSaveButton() {
      return this.currentIosVersion !== this.currentIosVersionFromServer
    },
    showCurrentAndroidVersionSaveButton() {
      return this.currentAndroidVersion !== this.currentAndroidVersionFromServer
    },
    showDaysBetweenNonPremiumUsersAttendingLiveStreamsSaveButton() {
      return this.daysBetweenNonPremiumUsersAttendingLiveStreams !== this.daysBetweenNonPremiumUsersAttendingLiveStreamsFromServer
    },
    isMinAndroidVersionCorrectForSemver() {
      return semver.valid(this.minimumAndroidVersion)
    },
    isCurrentAndroidVersionCorrectForSemver() {
      return semver.valid(this.currentAndroidVersion)
    },
    isMinIosVersionCorrectForSemver() {
      return semver.valid(this.minimumIosVersion)
    },
    isCurrentIosVersionCorrectForSemver() {
      return semver.valid(this.currentIosVersion)
    },
  },
  watch: {
    isInMaintenance(checked) {
      if (this.isRollback) {
        this.isRollback = false
      } else {
        this.changeMaintenanceStatus(checked)
      }
    },
    isSingleUserPushNotificationsEnabled(checked) {
      if (this.isSingleUserPushNotificationsEnabledRollback) {
        this.isSingleUserPushNotificationsEnabledRollback = false
      } else {
        this.changeIsSingleUserNotificationEnabled(checked)
      }
    },
    isBroadcastPushNotificationsEnabled(checked) {
      if (this.isBroadcastPushNotificationsEnabledRollback) {
        this.isBroadcastPushNotificationsEnabledRollback = false
      } else {
        this.changeIsBroadcastNotificationEnabled(checked)
      }
    },
  },
  mounted() {
    this.loadAppInfoStatus()
  },
  methods: {
    async changeMaintenanceStatus(checked) {
      try {
        await appInfoAdministration.changeAppMaintenanceStatus({ maintenanceStatus: checked })
        notification.success({
          message: 'İşlem Başarılı!',
          description: 'Sunucu Bakım Modu Başarıyla Güncellendi!',
        })
      } catch (e) {
        this.isRollback = true
        this.isInMaintenance = !checked
      }
    },
    async changeMinIosVersion() {
      if (!this.isMinIosVersionCorrectForSemver) {
        notification.warn({
          message: 'Minimum IOS versiyonu hatalı gözüküyor.',
          description: 'Güncellemek için semantic versiyonlamaya uygun formatta bir sürüm numarası giriniz. Semver hakkında daha detaylı bilgi için https://semver.org/lang/tr/ adresini ziyaret ediniz.',
        })
        return
      }

      try {
        await appInfoAdministration.changeAppMinimumIosVersion({ minimumIosVersion: this.minimumIosVersion })
        this.minimumIosVersionFromServer = this.minimumIosVersion
        notification.success({
          message: 'İşlem Başarılı!',
          description: 'Minimum IOS Versionu Başarıyla Güncellendi!',
        })
      } catch {

      }
    },
    async changeMinAndroidVersion() {
      if (!this.isMinAndroidVersionCorrectForSemver) {
        notification.warn({
          message: 'Minimum android sürümü hatalı gözüküyor.',
          description: 'Güncellemek için semantic versiyonlamaya uygun formatta bir sürüm numarası giriniz. Semver hakkında daha detaylı bilgi için https://semver.org/lang/tr/ adresini ziyaret ediniz.',
        })
        return
      }

      try {
        await appInfoAdministration.changeAppMinimumAndroidVersion({ minimumAndroidVersion: this.minimumAndroidVersion })
        this.minimumAndroidVersionFromServer = this.minimumAndroidVersion
        notification.success({
          message: 'İşlem Başarılı!',
          description: 'Minimum Android Versionu Başarıyla Güncellendi!',
        })
      } catch  {
      }
    },
    async changeCurrentIosVersion() {
      if (!this.isCurrentIosVersionCorrectForSemver) {
        notification.warn({
          message: 'Şu anki IOS versiyonu hatalı gözüküyor.',
          description: 'Güncellemek için semantic versiyonlamaya uygun formatta bir sürüm numarası giriniz. Semver hakkında daha detaylı bilgi için https://semver.org/lang/tr/ adresini ziyaret ediniz.',
        })
        return
      }

      try {
        await appInfoAdministration.changeAppCurrentIosVersion({ currentIosVersion: this.currentIosVersion })
        this.currentIosVersionFromServer = this.currentIosVersion
        notification.success({
          message: 'İşlem Başarılı!',
          description: 'Mevcut Ios Versionu Başarıyla Güncellendi!',
        })
      } catch {
      }
    },
    async changeCurrentAndroidVersion() {
      if (!this.isCurrentAndroidVersionCorrectForSemver) {
        notification.warn({
          message: 'Şu anki Android versiyonu hatalı gözüküyor.',
          description: 'Güncellemek için semantic versiyonlamaya uygun formatta bir sürüm numarası giriniz. Semver hakkında daha detaylı bilgi için https://semver.org/lang/tr/ adresini ziyaret ediniz.',
        })
        return
      }

      try {
        await appInfoAdministration.changeAppCurrentAndroidVersion({ currentAndroidVersion: this.currentAndroidVersion })
        this.currentAndroidVersionFromServer = this.currentAndroidVersion
        notification.success({
          message: 'İşlem Başarılı!',
          description: 'Mevcut Android Versionu Başarıyla Güncellendi!',
        })
      } catch {
      }
    },
    async changeDaysBetweenNonPremiumUsersAttendingLiveStreams() {
      try {
        await appInfoAdministration.changeDaysBetweenNonPremiumUsersAttendingLiveStreams({ daysBetweenNonPremiumUsersAttendingLiveStreams: this.daysBetweenNonPremiumUsersAttendingLiveStreams })
        this.daysBetweenNonPremiumUsersAttendingLiveStreamsFromServer = this.daysBetweenNonPremiumUsersAttendingLiveStreams
        notification.success({
          message: 'İşlem Başarılı!',
          description: 'Premium olmayan kullanıcıların canlı yayınlara katılma aralığı güncellendi!',
        })
      } catch {
      }
    },
    async changeIsSingleUserNotificationEnabled(checked) {
      try {
        await appInfoAdministration.changeIsSingleUserPushNotificationsEnabled({
          isSingleUserPushNotificationsEnabled: checked,
        })
        notification.success({
          message: 'İşlem Başarılı!',
          description: 'Tek kullanıcı bildirimleri açık/kapalı güncellendi!',
        })
      } catch {
        this.isSingleUserPushNotificationsEnabledRollback = true
        this.isSingleUserPushNotificationsEnabled = !checked
      }
    },
    async changeIsBroadcastNotificationEnabled(checked) {
      try {
        await appInfoAdministration.changeIsBroadcastPushNotificationsEnabled({
          isBroadcastPushNotificationsEnabled: checked,
        })
        notification.success({
          message: 'İşlem Başarılı!',
          description: 'Yayın bildirimleri açık/kapalı güncellendi!',
        })
      } catch {
        this.isBroadcastPushNotificationsEnabledRollback = true
        this.isBroadcastPushNotificationsEnabled = !checked
      }
    },
    async loadAppInfoStatus() {
      try {
        const { data } = await appInfoAdministration.getAppStatusForAdministration()
        this.minimumIosVersion = data.minimumIOSVersion
        this.minimumIosVersionFromServer = data.minimumIOSVersion
        this.currentIosVersion = data.currentIOSVersion
        this.currentIosVersionFromServer = data.currentIOSVersion
        this.minimumAndroidVersion = data.minimumAndroidVersion
        this.minimumAndroidVersionFromServer = data.minimumAndroidVersion
        this.currentAndroidVersion = data.currentAndroidVersion
        this.currentAndroidVersionFromServer = data.currentAndroidVersion
        this.isInMaintenance = data.inMaintenance
        this.isBroadcastPushNotificationsEnabled = data.isBroadcastPushNotificationsEnabled
        this.isSingleUserPushNotificationsEnabled = data.isSingleUserPushNotificationsEnabled
        this.daysBetweenNonPremiumUsersAttendingLiveStreamsFromServer = data.daysBetweenNonPremiumUsersAttendingLiveStreams
        this.daysBetweenNonPremiumUsersAttendingLiveStreams = data.daysBetweenNonPremiumUsersAttendingLiveStreams
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
